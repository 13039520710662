import cx from 'classnames';
import { User } from 'models/api/authentication/authentication.model';
import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getContentLogo } from 'api/content.api';
import images from '../../../assets/images';
import Language from './Language/Language';
import classes from './footer.module.scss';

interface Props {
  user: User;
}

const Footer: FC<Props> = ({ user }) => {
  const { t } = useTranslation('footer');
  const [source, setSource] = useState<any>('');
  const [country, setCountry] = useState('hk');
  const lang = localStorage.getItem('i18nextLng');

  const url = window.location.href;
  const domain = new URL(url);
  const newDomain = domain.pathname;

  useEffect(() => {
    if (lang === 'en') {
      setCountry('os');
    } else if (lang === 'tw') {
      setCountry('hk');
    } else {
      setCountry('cn');
    }

    getContentLogo(country).then((res) => {
      setSource(res?.data);
    });
  }, [country, lang]);

  const listItemFooter = [
    {
      title: t('newOrder'),
      path: '/order/new-order',
    },
    {
      title: t('backToSwisscoatOfficialWebsite'),
      path: source?.backToSwisscoatUrl,
    },
    {
      title: t('faceScanning'),
      path: source?.faceScanUrl,
    },
    {
      title: t('orderHistory'),
      path: '/order-history',
    },
    {
      title: t('contact'),
      path: '/about',
    },
    {
      title: t('aiScanning'),
      path: source?.aiScanUrl,
    },
  ];

  return (
    <div
      className={cx('bg-dark-blue text-white min-h-8 md:px-1', classes.footer)}
      style={{
        position: 'absolute',
        bottom: '-170px',
        width: '100%',
      }}>
      <div
        className={cx(
          'container md:max-w-700px mx-auto xl:max-w-1170px 2xl:max-w-1280px lg:max-w-980px 3xl:max-w-1536px',
          classes.wrapper,
        )}>
        <div className={'grid grid-cols-1 md:grid-cols-4'}>
          <div className={'block md:hidden mb-4'}>
            <Language />
          </div>
          {!(user?.type === 2) && (
            <div className="grid-cols-1 md:col-span-3 hidden md:grid">
              <ul className={cx(classes.items)}>
                {listItemFooter.map((item) => (
                  <li className={cx(classes.item)} key={item.title}>
                    <a href={item.path}>{item.title}</a>
                    {/* <Link to={item.path}>{item.title}</Link> */}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {newDomain === '/order/edit' ? (
            <div className={classes.logo}>
              <img src={images.footer.logo} alt="logoFooter" />
            </div>
          ) : (
            ''
          )}
          <div className={'hidden md:block md:col-end-6'}>
            <Language />
          </div>
        </div>

        <div className={cx(classes.divider, 'hidden md:block')} />

        <div
          className={cx(
            'flex flex-col md:flex-row items-center justify-between mt-6 md:mt-0',
            classes.bottom,
          )}>
          <div className={'flex'}>
            <Link to={'/terms'}>{t('termsAndConditions')}</Link>
            <img
              src={images.footer.separate}
              alt={'Seperate'}
              className={'mx-2'}
            />
            <Link to={'/privacy'}> {t('privacyPolicy')}</Link>
            <img
              src={images.footer.separate}
              alt={'Seperate'}
              className={'mx-2'}
            />
            <Link to={'/disclaimer'}> {t('disclaimer')}</Link>
          </div>
          <div className="mt-2 md:mt-0">粤ICP备18096159号-3</div>
          <div className={'mt-2 md:mt-0 text-center'}>
            © {new Date().getFullYear()} {t('description')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
