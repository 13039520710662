import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import images from '../../../assets/images';
import classes from './footer.module.scss';

const Footer = () => {
  const { t } = useTranslation('footer');

  return (
    <div className={cx('bg-dark-blue text-white flex', classes.footer)}>
      <div className={cx('container m-auto h-full')}>
        <div
          className={cx(
            'flex flex-col lg:flex-row items-center justify-between py-4 lg:py-0 h-full',
            classes.bottom,
          )}>
          <div className={'flex md:text-xs'}>
            <Link to={'/terms'}>{t('termsAndConditions')}</Link>
            <img
              src={images.footer.separate}
              alt={'Seperate'}
              className={'mx-2'}
            />
            <Link to={'/privacy'}> {t('privacyPolicy')}</Link>
            <img
              src={images.footer.separate}
              alt={'Seperate'}
              className={'mx-2'}
            />
            <Link to={'/disclaimer'}> {t('disclaimer')}</Link>
          </div>
          <div className="mt-2 md:mt-0 md:text-xs">粤ICP备18096159号-3</div>
          <div className={'pb-8 lg:pb-0 mt-2 md:mt-0 text-center md:text-xs'}>
            © {new Date().getFullYear()} {t('description')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
